import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  className?: string
  cin?: string
  cir?: string
  companyName?: string
  email?: string
  languageCode: string
  phone?: string
  pec?: string
  companyAddress?: string
  sdi?: string
  vat?: string
}

export const CompanyData = memo(function CompanyData({
  cin,
  cir,
  className,
  companyName,
  pec,
  languageCode,
  phone,
  companyAddress,
  sdi,
  vat,
}: Props) {
  const [modalStatus, setModalStatus] = useState(false)

  return (
    <Container>
      <Title
        className={className}
        onClick={() => {
          if (!modalStatus) {
            setModalStatus(true)
          } else {
            setModalStatus(false)
          }
        }}
      >
        {useVocabularyData('company-data', languageCode)}
      </Title>

      <Modal className={modalStatus ? ' open' : undefined}>
        <ModalClose
          languageCode={languageCode}
          onClick={() => {
            if (window.innerWidth > 1199) {
              disableScroll.off()
            } else {
              document.body.style.overflow = 'auto'
            }
            setModalStatus(false)
          }}
          modalStatus={modalStatus}
        />

        <Data>
          {companyName ? (
            <Row dial={5} row wrap>
              <Label>
                {useVocabularyData('company-name-label', languageCode)}
              </Label>
              <Item>{companyName}</Item>
            </Row>
          ) : null}

          {companyAddress ? (
            <Row dial={5} row wrap>
              <Label>
                {useVocabularyData('company-address-label', languageCode)}
              </Label>
              <Item>{companyAddress}</Item>
            </Row>
          ) : null}

          {pec ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('email', languageCode)}</Label>

              <Anchor
                href={`mailto:${pec}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {pec}
              </Anchor>
            </Row>
          ) : null}

          {phone ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('telephone', languageCode)}</Label>

              {phone ? (
                <Anchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {phone}
                </Anchor>
              ) : null}
            </Row>
          ) : null}

          {vat ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('vat', languageCode)}</Label>

              <Item>{vat}</Item>
            </Row>
          ) : null}
          {sdi ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('sdi', languageCode)}</Label>

              <Item>{sdi}</Item>
            </Row>
          ) : null}
          {cin ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('cin', languageCode)}</Label>

              <Item>{cin}</Item>
            </Row>
          ) : null}
          {cir ? (
            <Row dial={5} row wrap>
              <Label>{useVocabularyData('cir', languageCode)}</Label>

              <Item>{cir}</Item>
            </Row>
          ) : null}
        </Data>
      </Modal>
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  display: inline-block;

  @media (max-width: 1023px) {
    margin: auto;
    overflow-x: hidden;
  }
`

const Title = styled.div`
  cursor: pointer;
  color: ${theme.colors.variants.neutralLight4};
  opacity: 0.4;
  font-size: 0.6875rem;
  line-height: 1.375rem;
  transition: 0.1s ease-in-out;
  &:hover {
    opacity: 1;
  }

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
    text-align: center;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const Data = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 8.125rem 6.667vw;
  overflow-y: auto;

  @media (max-width: 1199px) {
    padding: 6.25rem 2.25rem;
  }
  @media (max-width: 767px) {
    padding: 5rem 1.875rem;
  }
`

const Row = styled(FlexBox)`
  max-width: 50rem;
  border-bottom: 0.0625rem solid
    ${rgba(theme.colors.variants.neutralDark4, 0.15)};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
  margin: auto;
  padding: 1.5rem 0;

  @media (max-width: 1023px) {
    display: block;
    max-width: none;
    text-align: center;
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 1.25rem 0;
  }
  @media (max-width: 767px) {
    line-height: 1.5rem;
    padding: 1rem 0;
  }
`

const Label = styled.div`
  width: 50%;
  font-size: 0.75vw;
  margin-bottom: 0;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
  font-family: ${theme.fontFamily.heading};
  color: ${theme.colors.variants.neutralDark4};

  @media (max-width: 1599px) {
    font-size: 0.75rem;
  }
  @media (min-width: 2000px) {
    font-size: 1rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Anchor = styled.a`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  transition: 0.2s ease-in-out;

  @media (max-width: 1023px) {
    width: auto;
  }
`

const Item = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: auto;
  }
`
